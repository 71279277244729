import './index.css';

import * as React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Layout>
  )
}

export default IndexPage;

export const query = graphql `
  {
    markdownRemark(frontmatter: {slug: {eq: "home"}}) {
        html
    }
  }
`;
